/* =============================================================================
 * Section
 *
 * postcss-bem-linter: define section
============================================================================= */
.o-section {
	padding-top:var(--space-l);
	padding-bottom:var(--space-l);
}
.o-section--compact {
	padding-top:var(--space-s);
	padding-bottom:var(--space-s);
}
.o-section--compact-top {
	padding-top:var(--space-s);
}
.o-section--compact-bottom {
	padding-bottom:var(--space-s);
}
.o-section--x-padding {
	padding-right:var(--space-s);
	padding-left:var(--space-s);
}
.o-section--x-padding:not(.o-section--compact) {
	padding-right:var(--space);
	padding-left:var(--space);
}
