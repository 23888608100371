/* =============================================================================
 * Text transform
============================================================================= */

/* postcss-bem-linter: define text-transform-uppercase */
.t-text-transform-uppercase {
	text-transform:uppercase !important;
}

/* postcss-bem-linter: define text-transform-lowercase */
.t-text-transform-lowercase {
	text-transform:lowercase !important;
}

/* postcss-bem-linter: define text-transform-none */
.t-text-transform-none {
	text-transform:none !important;
}
