/* =============================================================================
 * Card-list
 *
 * postcss-bem-linter: define card-list
============================================================================= */
.c-card-list {
	display:flex;
	overflow-x:auto;
	overflow-y:hidden;
	-webkit-overflow-scrolling:touch;
	scroll-snap-type:inline mandatory;
}
.c-card-list__item {
	flex:1 1 280px;
	min-width:210px;
	max-width:280px;
	scroll-snap-align:center;
}
.c-card-list__item--wide {
	flex-basis:480px;
	min-width:400px;
	max-width:480px;
}

@media screen and (max-width:499px) {
	.c-card-list__item--wide {
		min-width:80%;
	}
}
.c-card-list__item + .c-card-list__item {
	margin-left:var(--space-s);
}
.c-card-list__item > * {
	height:100%;
}
