/* =============================================================================
 * Width limiter
 * Limits width and centers container on page.
 * postcss-bem-linter: define width-limiter
============================================================================= */
.o-width-limiter {
	max-width:var(--container);
	margin-right:auto;
	margin-left:auto;
	padding-right:var(--gutter);
	padding-left:var(--gutter);
}
