/* =============================================================================
 * Spinner
 * postcss-bem-linter: define spinner
============================================================================= */
.c-spinner {
	display:flex;
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:1;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	background:rgba(255, 255, 255, 0.8);
}
.c-spinner__graphics {
	position:relative;
}
.c-spinner__text {
	margin-top:20px;
	color:var(--black);
}


/* Spinner styles
 ---------------------------------------------------------------------------- */

/*
 * Two rotating and contracting/expanding balls
 */
.c-spinner--balls .c-spinner__graphics {
	position:relative;
	width:40px;
	height:40px;
	animation:spinner-balls-rotate 1.2s infinite linear;
}
.c-spinner--balls .c-spinner__graphics-part {
	position:absolute;
	top:0;
	width:60%;
	height:60%;
	border-radius:100%;
	background-color:var(--blue);
	content:"";
	animation:spinner-balls-bounce 1.2s infinite ease-in-out;
}
.c-spinner--balls .c-spinner__graphics-part--2 {
	top:auto;
	bottom:0;
	content:"";
	animation-delay:-0.6s;
}

@keyframes spinner-balls-rotate {
	100% {
		transform:rotate(360deg);
	}
}

@keyframes spinner-balls-bounce {
	0%,
	100% {
		transform:scale(0);
	}
	50% {
		transform:scale(1);
	}
}

/*
 * Two contracting and expanding balls
 */
.c-spinner--double-bounce .c-spinner__graphics {
	position:relative;
	width:40px;
	height:40px;
}
.c-spinner--double-bounce .c-spinner__graphics-part {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	border-radius:50%;
	background-color:var(--blue);
	opacity:0.6;
	animation:double-bounce 2s infinite ease-in-out;
}
.c-spinner--double-bounce .c-spinner__graphics-part--2 {
	animation-delay:-1s;
}

@keyframes double-bounce {
	0%,
	100% {
		transform:scale(0);
	}
	50% {
		transform:scale(1);
	}
}
