/* =============================================================================
 * Block-list
 *
 * postcss-bem-linter: define block-list
============================================================================= */
.o-block-list {
	display:flex;
	justify-content:center;
	flex-wrap:wrap;
}
.o-block-list > * {
	min-width:300px;
	max-width:100%;
	width:calc(100% / 3);
}

@media screen and (max-width:768px) {
	.o-block-list > * {
		flex-grow:1;
	}
}
