/* =============================================================================
 * Thumb-navigation
 *
 * 1. Trigger width - thumb width - border widths
 *
 * postcss-bem-linter: define thumb-navigation
============================================================================= */

/* Reset list styling but maintain list semantics
----------------------------------------------------------------------------- */
.c-thumb-navigation__list {
	margin:0;
	padding-left:0;
	list-style:none;
}
.c-thumb-navigation__item::before {
	position:absolute;
	content:"\200B";
}

/* Styling
----------------------------------------------------------------------------- */
.c-thumb-navigation__list {
	display:flex;
	position:relative;
	flex-wrap:wrap;
	overflow:hidden;
	margin-right:auto;
	margin-left:auto;
	padding:15px 0;
}
.c-thumb-navigation__item {
	flex:0 0 auto;
}
.c-thumb-navigation__link {
	display:inline-flex;
	flex-direction:column;
	justify-content:flex-end;
	align-items:center;
	width:115px;
	height:100%;
	padding:4px 4px 25px;
	text-align:center;
}
.c-thumb-navigation__link:focus .c-thumb-navigation__text {
	outline:var(--focus-ring);
}
.c-thumb-navigation__link[aria-current]::after {
	position:absolute;
	z-index:-1;
	width:0;
	height:100%;
	margin-top:2px;
	border-left:dotted 4px var(--blue);
	content:"";
	transform:translateY(100%);
}
.c-thumb-navigation__text {
	max-width:100%;
	box-shadow:0 -5px 4px 0 #fff, 0 10px 4px #fff;
	background-color:#fff;
	font-weight:700;
	font-size:14px;
	word-wrap:break-word;
	overflow-wrap:break-word;
}
.c-thumb-navigation__thumb {
	position:relative;
	margin-top:4px;
	border:solid 2px transparent;
	border-radius:88px;
	transition:border-color 0.1s ease;
}
.c-thumb-navigation__thumb img {
	border-radius:88px;
}
.c-thumb-navigation__link:hover .c-thumb-navigation__thumb,
.c-thumb-navigation__link:focus .c-thumb-navigation__thumb,
.c-thumb-navigation__link[aria-current] .c-thumb-navigation__thumb {
	border-color:var(--blue);
}
.c-thumb-navigation__item:not(:last-of-type) .c-thumb-navigation__thumb::after {
	position:absolute;
	top:50%;
	left:100%;
	z-index:-2;
	width:calc(115px - 88px - 10px); /* 1 */
	height:0;
	margin-left:5px;
	border-top:dotted 4px var(--blue);
	content:"";
	transform:translateY(-50%);
}
.c-thumb-navigation__item--current:not(:last-of-type) .c-thumb-navigation__thumb::after,
.c-thumb-navigation__item--current ~ .c-thumb-navigation__item:not(:last-of-type) .c-thumb-navigation__thumb::after {
	border-top-color:var(--light-gray-2);
}

/* Media queries
----------------------------------------------------------------------------- */
@media screen and (max-width:1199px) {
	.c-thumb-navigation__list {
		max-width:600px;
	}
}

/* Center items
----------------------------------------------------------------------------- */
.c-thumb-navigation--center .c-thumb-navigation__list {
	justify-content:center;
}
