/* =============================================================================
 * Utility
============================================================================= */
.hidden {
	display:none;
}

@media (min-width:769px) {
	.hidden--desktop {
		display:none;
	}
}

@media (max-width:768px) {
	.hidden--mobile {
		display:none;
	}
}
