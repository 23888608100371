/* =============================================================================
 * Document-table
 *
 * 1. 80px is the image width
 * 2. 54px is the button width
 * 3. Needed to reset nth-last-child(-n+3)
 *
 * postcss-bem-linter: define document-table
============================================================================= */
.c-document-table__body .c-document-table__row:nth-child(odd) {
	background-color:var(--light-gray-4);
}
.c-document-table__heading {
	background-color:var(--blue);
	color:#fff;
}
.c-document-table__heading,
.c-document-table__col {
	padding:var(--space-s);
	vertical-align:middle;
}
.c-document-table .c-document-table__col--image {
	display:flex;
	justify-content:center;
	align-items:center;
}
.c-document-table__image {
	max-width:230px;
	max-height:230px;
	width:auto;
	height:auto;
}
.c-document-table .c-document-table__file-icon {
	width:100px;
	height:100px;
}
.c-document-table__col--name {
	word-break:break-all;
}

@media screen and (min-width:601px) {
	.c-document-table__col {
		width:100px;
	}
	.c-document-table__col--image {
		width:calc(80px + var(--space-m)); /* 1 */
		height:calc(80px + var(--space-m)); /* 1 */
	}
	.c-document-table__image {
		max-width:100%;
		max-height:100%;
	}
	.c-document-table .c-document-table__file-icon {
		width:100%;
		height:100%;
	}
	.c-document-table__col--name {
		width:auto;
	}
	.c-document-table__col--download {
		width:calc(54px + var(--space-m)); /* 2 */
	}
}

/* Grid layout
----------------------------------------------------------------------------- */
.c-document-table--grid .c-document-table__head {
	display:none;
}
.c-document-table--grid .c-document-table__body {
	display:grid;
	grid-template-columns:repeat(3, 1fr);
}
.c-document-table--grid .c-document-table__row {
	display:flex;
	flex-wrap:wrap;
}
.c-document-table--grid .c-document-table__row:nth-last-child(-n+3) {
	padding-bottom:var(--space-s);
}
.c-document-table--grid .c-document-table__col:not(:last-child) {
	padding-bottom:0;
}
.c-document-table--grid .c-document-table__col {
	width:50%;
}
.c-document-table--grid .c-document-table__col--image,
.c-document-table--grid .c-document-table__col--name,
.c-document-table--grid .c-document-table__col--download {
	width:100%;
}
.c-document-table--grid .c-document-table__col--image {
	height:calc(145px + var(--space-s));
}
.c-document-table--grid .c-document-table__image {
	max-width:100%;
	max-height:100%;
}
.c-document-table--grid .c-document-table__file-icon {
	height:100px;
}
.c-document-table--grid .c-document-table__col--download {
	height:calc(54px + var(--space-m)); /* 2 */
	margin-top:auto;
}

@media screen and (max-width:580px) {
	.c-document-table--grid .c-document-table__body {
		grid-template-columns:repeat(2, 1fr);
	}
	.c-document-table--grid .c-document-table__row:nth-child(1n) {
		background-color:var(--white);
	}
	.c-document-table--grid .c-document-table__row:nth-child(4n+0),
	.c-document-table--grid .c-document-table__row:nth-child(4n+1) {
		background-color:var(--light-gray-4);
	}
	.c-document-table--grid .c-document-table__row:nth-last-child(-n+3) {
		padding-bottom:0; /* 3 */
	}
	.c-document-table--grid .c-document-table__row:nth-last-child(-n+2) {
		padding-bottom:var(--space-s);
	}
}


/* Background row highlight when hovering
----------------------------------------------------------------------------- */
.c-document-table__body .c-document-table__row--selectable:hover {
	background-color:#eaf2fb;
}
