/* =============================================================================
 * Accordion
 * TODO: Byt ut vissa värden mot variabler, t.ex padding, font-size etc
============================================================================= */
.c-accordion__trigger {
	margin:0;
	padding:0;
	border:none;
	background-color:transparent;
	font:inherit;
}
.c-accordion__content {
	padding-top:var(--space-xs);
}
.c-accordion--is-collapsed .c-accordion__content {
	display:none;
}
.c-accordion--is-expanded .c-accordion__content {
	display:block;
}
.c-accordion .c-accordion__trigger {
	margin-bottom:0;
}
.c-accordion__button {
	display:flex;
	position:relative;
	align-items:center;
	padding:0;
	border:none;
	background-color:transparent;
	color:var(--blue);
	font-size:16px;
	text-align:left;
	text-decoration:none;
	cursor:pointer;
}
.c-accordion__icon {
	display:inline-flex;
	position:absolute;
	top:50%;
	left:0;
	width:11px;
	height:11px;
	transform:translateY(-50%);
	fill:var(--blue);
}
.c-accordion__icon svg {
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%, -50%);
}
