/* =============================================================================
 * Search
 *
 * postcss-bem-linter: define search
============================================================================= */
.c-search {
	padding:var(--space-s) 0 var(--space-m);
}
.c-search__form {
	display:flex;
	justify-content:center;
	overflow:hidden;
	margin:0 auto;
	border-radius:50px;
	box-shadow:inset 0 0 4px rgba(0, 0, 0, 0.2);
	background-color:var(--light-gray-4);
}
.c-search__input {
	flex:1 0 auto;
	min-height:44px;
	padding:10px 5px 10px 20px;
	border:none;
	background-color:transparent;
	font-size:16px;
	font-family:var(--font-regular);
	-moz-appearance:none;
	-webkit-appearance:none;
	appearance:none;
}
.c-search__form .c-search__input {
	border-top-left-radius:22px;
	border-bottom-left-radius:22px;
}
.c-search__input::-webkit-input-placeholder { /* Edge */
	color:#707070;
}
.c-search__input:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color:#707070;
}
.c-search__input::placeholder {
	color:#707070;
}
.c-search__submit {
	padding:0;
	padding:0 16px 0 10px;
	border:none;
	background-color:transparent;
	color:initial;
	cursor:pointer;
}
.c-search__submit:focus {
	background-color:var(--blue);
}
.c-search__submit:focus svg {
	fill:#fff;
}
