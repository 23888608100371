/* =============================================================================
 * Card
 *
 * postcss-bem-linter: define card
============================================================================= */
.c-card {
	display:flex;
	flex-direction:column;
	overflow:hidden;
	padding:var(--space-xs);
	background-color:#fff;
}
.c-card__image-container {
	position:relative;
	height:0;
	overflow:hidden;
	padding-bottom:50%;
	background-color:var(--light-gray-4);
}
.c-card__image {
	position:absolute;
	top:50%;
	width:100%;
	transform:translateY(-50%);
}
.c-card__content-container,
.c-card__shop-container {
	padding:var(--space-xs);
}
.c-card .c-card__name {
	position:relative;
	margin-bottom:0;
	font-size:20px;
}
.c-card__image-container + .c-card__content-container .c-card__link::before {
	position:absolute;
	top:-1000px;
	right:calc(var(--space-s) * -1);
	bottom:0;
	left:calc(var(--space-s) * -1);
	content:"";
}
.c-card__link {
	text-decoration:underline;
}
.c-card__link:focus .c-card__link-text {
	outline:var(--focus-ring);
	outline-offset:2px;
}
.c-card .c-card__part-no {
	margin-bottom:0;
}
.c-card__accordion {
	margin-top:var(--space-xs);
}
.c-card__description {
	max-height:250px;
	overflow-y:auto;
	margin-right:calc(var(--space-s) * -1);

	/* Scroll shadows */
	background:linear-gradient(#fff 33%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), #fff 66%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(48, 48, 48, 0.5), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(48, 48, 48, 0.5), rgba(0, 0, 0, 0)) 0 100%;
	background-color:#fff;
	background-attachment:local, local, scroll, scroll;
	background-size:100% 24px, 100% 24px, 100% 8px, 100% 8px;
	background-repeat:no-repeat;
	line-height:1.2;
}
.c-card__shop-container {
	margin-top:auto;
	padding:0 var(--space-xs);
}
.c-card__form {
	margin:0;
}
.c-card__price {
	font-weight:700;
}
.c-card__package {
	font-size:14px;
}
.c-card__shop-container p {
	margin-bottom:0;
}
.c-card__link--wrap {
	display:grid;
	justify-content:center;
	align-content:start;
	height:100%;
}

/* New card
----------------------------------------------------------------------------- */
.new-card {
	display:grid;
	position:relative;
	align-items:stretch;
	padding:7.5px;
	background-color:#fff;
}
.new-card__align {
	display:grid;
}
.new-card__align--top {
	align-content:start;
}
.new-card__align--bottom {
	align-items:flex-end;
}
.new-card__name[class] {
	margin:0 auto 0.25rem;
	font-size:20px;
	line-height:28px;
}
.new-card__image-container {
	display:flex;
	max-height:210px;
	width:100%;
	aspect-ratio:1/1;
}
.c-card-list__item--wide .new-card__image-container {
	aspect-ratio:2/1.04;
}
.new-card__image {
	width:100%;
	height:100%;
	object-fit:cover;
}
.new-card__content-container {
	padding:var(--space-xs);
}
.new-card__description * {
	font-size:14px;
	line-height:1.2;
	font-family:var(--font-light);
}
.new-card__link {
	display:flex;
	flex-direction:row-reverse;
	justify-content:start;
	align-items:center;
	color:var(--blue);
	font-size:16px;
	line-height:1;
	font-family:"Inter", sans-serif;
	text-align:center;
	column-gap:0.5rem;
}
.new-card__link--underline {
	text-decoration:underline;
}
.new-card__link--center {
	justify-content:center;
}
.new-card__link--wrap::after {
	display:block;
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	content:"";
}
.new-card__link::before {
	margin-left:0;
	color:var(--blue);
	font-size:24px;
	line-height:18px;
	font-family:"icons", sans-serif;
	content:"\e907";
	transition:margin 0.6s ease;
}
.new-card__link:hover::before {
	margin-left:0.5rem;
}
.new-card__link:hover {
	color:var(--blue);
	opacity:0.7;
}
.new-card__part-no {
	margin:0 auto 0.5rem;
	font-size:14px;
	line-height:1.2;
	font-family:var(--font-light);
}

/* Descripion scope, elements spacing etc
----------------------------------------------------------------------------- */
.c-card__description * {
	font-size:14px;
	line-height:1.2;
	font-family:var(--font-light);
}
.c-card__description * + * {
	margin-top:var(--space-xs);
}
.c-card__description h4 {
	display:inline;
	font-weight:700;
}
.c-card__description h4::after,
.c-card__description caption::after {
	content:": ";
}
.c-card__description h4 + * {
	margin-top:0;
}
.c-card__description p {
	display:inline;
	margin-bottom:0;
}
.c-card__description p::after {
	display:block;
	margin-top:var(--space-xs);
	content:"";
}
.c-card__description table:not(:last-child) {
	margin-bottom:var(--space-xs);
}
.c-card__description caption {
	font-weight:700;
	text-align:left;
}
.c-card__description tr:nth-child(odd) {
	background-color:rgba(0, 0, 0, 0.1);
}
.c-card__description td {
	padding:2px 0;
}
.c-card__description td:nth-child(2) {
	padding-left:4px;
}
.c-card__content-container i {
	font-size:14px;
}
