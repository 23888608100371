/* =============================================================================
 * Place
 *
 * postcss-bem-linter: define place
============================================================================= */
.o-place > * + * {
	margin-top:0;
}
.o-place {
	display:flex;
	flex-wrap:wrap;
	align-items:flex-start;
}
.o-place:not(.o-place--collapse) {
	margin-bottom:calc(var(--space-m) * -1);
}
.o-place > * {
	margin-bottom:var(--space-m);
}
.o-place > *:not(:last-child) {
	margin-right:var(--space-s);
}
.o-place > *:only-child {
	margin-right:0;
	margin-left:0;
}


/* Center
----------------------------------------------------------------------------- */
.o-place--center {
	justify-content:center;
}
.o-place--center > *:not(:last-child),
.o-place--center > *:last-child {
	margin-right:var(--space-xs);
	margin-left:var(--space-xs);
}
.o-place--center > *:only-child {
	margin-right:0;
	margin-left:0;
}


/* Center vertically
----------------------------------------------------------------------------- */
.o-place--center-vertical {
	align-items:center;
}


/* Right
----------------------------------------------------------------------------- */
.o-place--right {
	justify-content:flex-end;
}
.o-place--right > *:not(:last-child) {
	margin-right:0;
}
.o-place--right > *:not(:first-child) {
	margin-left:var(--space-s);
}

/* Space between
----------------------------------------------------------------------------- */
.o-place--space-between {
	justify-content:space-between;
}
.o-place--space-between > *:only-child {
	margin-right:auto;
	margin-left:auto;
}


/* Justify
----------------------------------------------------------------------------- */

@media screen and (max-width:25em) {
	.o-place--justify > *:not(:last-child):not(:first-child),
	.o-place--justify > *:first-child,
	.o-place--justify > *:last-child {
		flex:1 0 100%;
		margin-right:0;
		margin-left:0;
	}
}


/* Collapse
----------------------------------------------------------------------------- */
.o-place--collapse > *:not(:last-child):not(:first-child),
.o-place--collapse > *:first-child,
.o-place--collapse > *:last-child {
	margin:0;
}
