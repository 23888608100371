/* =============================================================================
 * Recommended
 *
 * postcss-bem-linter: define recommended
============================================================================= */
.c-recommended__inner {
	padding-top:var(--space-m);
	padding-bottom:var(--space-m);
}
.c-recommended__no-items {
	font-style:italic;
}
.c-recommended__cards {
	text-align:left;
}
.c-recommended__link {
	margin-top:var(--space-s);
}
.c-recommended__cards .c-card__image-container {
	max-height:205px;
	height:auto;
	padding-bottom:0;
	aspect-ratio:1/1.04;
}
.c-recommended__cards .c-card__image-container .c-card__image {
	position:relative;
	top:auto;
	transform:none;
}
.c-recommended__cards .c-card-list__item--wide {
	flex:1 1 540px;
	min-width:405px;
}
.c-recommended__cards .c-card-list__item--wide .c-card__image-container {
	aspect-ratio:1/0.52;
}
