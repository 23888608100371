/* =============================================================================
 * Pagination
 * postcss-bem-linter: define pagination
============================================================================= */
.c-pagination {
	margin-top:var(--space-m);
}
.c-pagination__label {
	display:block;
	margin-bottom:var(--space-s);
	text-align:center;
}
.c-pagination ul {
	display:flex;
	justify-content:center;
	flex-wrap:wrap;
	margin:0;
	padding:0;
	list-style:none;
	text-align:center;
}
.c-pagination li {
	display:inline-flex;
	justify-content:center;
	align-items:center;
	min-width:44px;
	min-height:44px;
	margin:0;
	font-size:0;
}
.c-pagination a {
	display:inline-flex;
	justify-content:center;
	align-items:center;
	min-width:24px;
	min-height:24px;
	margin:0;
	border-radius:2px;
	font-weight:700;
	font-size:14px;
	text-align:center;
}


/* Current page
----------------------------------------------------------------------------- */
.c-pagination__current a {
	background-color:var(--blue);
	color:#fff;
	font-weight:bold;
}

/* Previous and Next
----------------------------------------------------------------------------- */
.c-pagination__previous a,
.c-pagination__next a {
	background-color:var(--light-gray-2);
}
.c-pagination a:focus {
	outline:var(--focus-ring);
	outline-offset:-8px;
}
