/* =============================================================================
 * Amount
 *
 * postcss-bem-linter: define amount
============================================================================= */
.c-amount {
	display:inline-flex;
	background-color:var(--light-gray-4);
}
.c-amount__button,
.c-amount__input[class] {
	width:44px;
	height:44px;
	padding:0;
	border:none;
	background-color:transparent;
	text-align:center;
}
.c-amount__input {
	width:100px;
	font-size:13px;
	font-family:var(--family-primary);
}
.c-amount--enabled .c-amount__input {
	width:30px;
}
.c-amount__input:focus,
.c-amount__button:focus {
	outline:var(--focus-ring);
	outline-offset:-3px;
}
.c-amount__button:active {
	color:var(--black);
	transform:scale(0.9);
}
.c-amount--enabled .c-amount__input::-webkit-outer-spin-button,
.c-amount--enabled .c-amount__input::-webkit-inner-spin-button {
	margin:0;
	-webkit-appearance:none;
}
.c-amount--enabled .c-amount__input[type=number] {
	-moz-appearance:textfield;
}
