/* =============================================================================
 * Search-result
 *
 * postcss-bem-linter: define search-result
============================================================================= */

/* Layout
----------------------------------------------------------------------------- */
.c-search-result {
	display:grid;
	grid-gap:var(--space-s);
}

@media screen and (min-width:769px) {
	.c-search-result {
		/* grid-template-columns:240px auto; */
	}
	.c-search-result__list {
		padding-top:var(--space-xs);
		padding-left:0; /* remove if filter */

		/* padding-left:var(--space-s); */

		/* border-left:solid 1px var(--light-gray-2); */
	}
	.c-search-result__filters {
		padding-top:var(--space-s);
	}
}

/* Reset list styling but maintain list semantics
----------------------------------------------------------------------------- */
.c-search-result__list {
	margin:0;
	list-style:none;
}
.c-search-result__item::before {
	position:absolute;
	content:"\200B";
}

/* List item
----------------------------------------------------------------------------- */
.c-search-result__item {
	display:flex;
	position:relative;
	flex-wrap:wrap;
	padding:var(--space-s) 0;
}
.c-search-result__item + .c-search-result__item {
	border-top:solid 1px var(--light-gray-2);
}
.c-search-result__image-container {
	display:flex;
	justify-content:center;
	align-items:center;
	flex:1 0 25%;
	padding-bottom:4%;
	text-align:center;
}
.c-search-result__content-container {
	flex:1 0 100%;
}
.c-search-result__cta-container {
	display:flex;
	z-index:1;
	justify-content:flex-end;
	align-items:center;
	flex:1 0 100%;
	margin-top:var(--space-s);
}

@media screen and (min-width:400px) {
	.c-search-result__image-container {
		flex-grow:0;
		padding-bottom:0;
	}
	.c-search-result__content-container {
		flex-basis:50%;
		padding-left:2%;
	}
	.c-search-result__content-container:first-child {
		margin-left:25%;
	}
}

@media screen and (min-width:880px) and (max-width:1160px) {
	.c-search-result__cta-container {
		max-width:280px;
		margin-left:auto;
		padding-left:2%;
	}
}

@media screen and (min-width:880px) {
	.c-search-result__content-container {
		flex-basis:350px;
	}
	.c-search-result__cta-container {
		flex-basis:auto;
		padding-left:6%;
	}
}


/* Product
----------------------------------------------------------------------------- */
.c-search-result__item--product {
	padding:0;
}

@media screen and (min-width:400px) {
	.c-search-result__item--product .c-search-result__content-container {
		flex-basis:30%;
		padding-left:2%;
	}
}

@media screen and (min-width:880px) and (max-width:1160px) {
	.c-search-result__item--product .c-search-result__cta-container {
		max-width:none;
		margin-left:auto;
		padding-left:2%;
	}
}


/* Content styling
----------------------------------------------------------------------------- */
.c-search-result__icon {
	width:4rem;
	height:4rem;
	color:#aaa;
	font-size:4rem;
}
.c-search-result__image {
	width:100%;
}
.c-search-result__item--product .c-search-result__image {
	width:100px;
}
.c-search-result__content-container > * {
	max-width:65ch;
}
.c-search-result__content-container > *:not(:last-child) {
	margin-bottom:15px;
}
.c-search-result__link {
	text-decoration:underline;
}
.c-search-result__link:focus {
	outline:var(--focus-ring);
	outline-offset:3px;
}
.c-search-result__cta-container > * + * {
	margin-left:var(--space-s);
}
.c-search-result__image-container + .c-search-result__content-container .c-search-result__link::before {
	position:absolute;
	top:var(--space-s);
	left:0;
	width:100%;
	padding-top:66.666%;
	content:"";
}
.c-search-result__disclaimer {
	margin-top:-12px;
	margin-bottom:4px;
	font-size:13px;
}


@media screen and (min-width:400px) {
	.c-search-result__image-container + .c-search-result__content-container .c-search-result__link::before {
		bottom:var(--space-s);
		width:25%;
		padding-top:0;
	}
}
.c-search-result__form {
	display:flex;
	justify-content:flex-end;
	align-items:center;
	margin:0 -5px;
}
.c-search-result__form-row {
	display:flex;
	justify-content:flex-end;
	align-items:center;
}

@media screen and (max-width:399px) {
	.c-search-result__form-row {
		flex-wrap:wrap;
	}
}
.c-search-result__form-row > * {
	margin:5px;
}
.c-search-result__price {
	font-weight:700;
	font-size:16px;
	white-space:nowrap;
}
.c-search-result__pack {
	white-space:nowrap;
}
