/* =============================================================================
 * Filter
 *
 * postcss-bem-linter: define filter
============================================================================= */
.c-filter + * {
	margin-top:var(--space-m);
}
.c-filter__form {
	display:flex;
	flex-wrap:wrap;
	margin:calc(var(--space-s) * -1);
}
.c-filter__group {
	display:flex;
	flex-wrap:wrap;
	flex:1 1 auto;
	margin:var(--space-s);

	/* padding:var(--space-s); */
	padding:var(--space-s) 0 0;

	/* border:1px solid var(--light-gray-1); */
	border:none;
	border-radius:3px;
}
.c-filter__group-name {
	width:100%;
	padding:0;
	font-weight:700;
	font-size:20px;
	font-family:var(--font-bold);
}
.c-filter__group > * {
	margin:var(--space-s) var(--space-m) 0 0;
}
.c-filter__submit {
	margin-top:var(--space-s);
}
