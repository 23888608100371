/* =============================================================================
 * Tabs
 *
============================================================================= */


/* The tabs container
----------------------------------------------------------------------------- */
* + .c-tabs {
	margin-top:var(--space-m);
}


/* The tablist
----------------------------------------------------------------------------- */
.c-tabs__tablist {
	display:flex;
	flex-wrap:wrap;
	margin:-10px 0 0 0;
	border-bottom:solid 1px var(--light-blue);
	list-style:none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.c-tabs__tablist--carousel::-webkit-scrollbar {
	display:none;
}
.c-tabs__tablist li,
.c-tabs__tablist-item {
	flex:0 0 auto;
}
.c-tabs__tablist li:not(:last-child),
.c-tabs__tablist-item:not(:last-child) {
	margin-right:var(--space-m);
}
.c-tabs__tablist a,
.c-tabs__tablist-trigger {
	display:block;
	margin-top:10px;
	padding:10px 0 6px;
	border:none;
	border-bottom:solid 4px transparent;
	background-color:transparent;
	font-size:16px;
	text-align:center;
	cursor:pointer;
}
.c-tabs__tablist a:not(.c-tabs__tablist-trigger) {
	border-bottom-color:var(--blue);
}
.c-tabs__tablist a:focus,
.c-tabs__tablist-trigger:focus {
	outline:var(--focus-ring);
	outline-offset:5px;
}
.c-tabs__tablist a:active,
.c-tabs__tablist-trigger:active {
	color:var(--black);
}
.c-tabs__tablist-trigger--active,
.c-tabs__tablist-trigger[aria-selected="true"] {
	border-bottom-color:var(--blue);
}


/* The tabpanels
*
* 1. Needed for positioning spinner
----------------------------------------------------------------------------- */
.c-tabs--enabled .c-tabs__tabpanel {
	position:relative; /* 1 */
	min-height:200px;
	padding-top:var(--space-s);
}
.c-tabs:not(.c-tabs--enabled) .c-tabs__tabpanel,
.c-tabs--no-js .c-tabs__tabpanel {
	margin-top:var(--space-s);
}
.c-tabs:not(.c-tabs--enabled):not(.c-tabs--load-on-demand) .c-tabs__tabpanel {
	display:none;
}
.c-tabs:not(.c-tabs--enabled):not(.c-tabs--load-on-demand) .c-tabs__tabpanel:target {
	display:block;
}

/* Search styling
----------------------------------------------------------------------------- */
.c-tabs--search .c-tabs__tablist {
	justify-content:center;
	border-bottom-color:var(--light-gray-2);
}

/* Search styling
----------------------------------------------------------------------------- */
@media (max-width:768px) {
	.c-tabs__tablist--carousel[class][class] {
		justify-content:normal;
		flex-wrap:nowrap;
		overflow:scroll;
		scroll-behavior:smooth;
		-ms-overflow-style:none;  /* IE and Edge */
		scrollbar-width:none;  /* Firefox */
		scroll-snap-type:x mandatory;
	}
	.c-tabs__tablist--carousel li {
		scroll-snap-align:start;
	}
}
