/* =============================================================================
 * Hero
 *
 * postcss-bem-linter: define hero
============================================================================= */
.c-hero {
	display:flex;
	position:relative;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	max-width:1920px;
	margin-right:auto;
	margin-left:auto;
	padding:50px 15px;
	background:var(--black);
}
.c-hero__image img,
.c-hero__image::before {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
}
.c-hero__image img {
	width:100%;
	height:100%;
	object-fit:cover;
}
.c-hero__image::before {
	z-index:1;
	background-color:rgba(0, 0, 0, 0.4);
	content:"";
}
.c-hero__content {
	z-index:2;
	max-width:100%;
	width:620px;
	text-align:center;
}
.c-hero__content > * + * {
	margin-top:15px;
}
.c-hero__content,
.c-hero__heading {
	color:#fff;
}
.c-hero .c-hero__heading {
	margin-bottom:0;
}
.c-hero__preamble {
	font-size:20px;
	line-height:24px;
}
.hero-width--1920 {
	max-width:1920px;
	margin-right:auto;
	margin-left:auto;
}

/* Media queries
----------------------------------------------------------------------------- */
@media screen and (min-width:769px) {
	.c-hero {
		min-height:300px;
		max-height:50vh;
		height:400px;
		padding:0;
	}
}
