/* =============================================================================
 * Product
 *
 * postcss-bem-linter: define product
============================================================================= */
.c-product {
	--image-size:80px;
	--spacing:12px;
	display:flex;
	width:100%;
	padding:12px;
	background-color:#f8fbfe;
}
.c-product ~ .c-product {
	border-top:1px solid #ececec;
}
.c-product__image {
	flex-shrink:0;
	width:var(--image-size);
	height:var(--image-size);
	margin-right:calc(var(--spacing) * 2);
	padding:6px;
	border:1px solid #e0e0e0;
}
.c-product__container {
	flex-grow:1;
}
.c-product__header,
.c-product__form {
	display:flex;
	justify-content:flex-end;
	align-items:center;
	gap:calc(var(--spacing) * 2);
	font-size:18px;
	line-height:18px;
}

@media (max-width:890px) {
	.c-product__header,
	.c-product__form {
		justify-content:flex-start;
		flex-wrap:wrap;
		row-gap:var(--spacing);
	}
}
.c-product__header {
	width:100%;
}
.c-product__title {
	width:100%;
	margin-right:auto;
}

@media (min-width:890px) {
	.c-product__title {
		max-width:40%;
		width:auto;
	}
}
.c-product__heading[class] {
	margin-bottom:0.5rem;
	color:var(--blue);
	font-weight:700;
	font-size:18px;
	font-family:var(--family-primary);
	text-align:left;
}
.c-product__heading[class] a {
	color:inherit;
}
.c-product__heading[class] a:hover {
	text-decoration:underline;
}
.c-product__byline[class] {
	margin-bottom:4px;
	font-size:16px;
}
.c-product__package {
	display:flex;
	justify-content:space-between;
	flex-wrap:wrap;
	max-width:35rem;
	margin-bottom:0.5rem;
	padding:0.75rem;
	background:#eaf2fb;
	color:#004a9d;
	font-size:14px;
}
.c-product__actions {
	display:flex;
	justify-content:flex-end;
	align-items:center;
	gap:calc(var(--spacing) * 2);
}

@media (max-width:890px) {
	.c-product__actions {
		margin-left:auto;
	}
}
.c-product__actions .button--emptyCart {
	justify-content:unset;
	min-width:auto;
	min-height:auto;
	width:auto;
	height:auto;
	padding:0.75rem !important;
	border-radius:3px;
}
.c-product__actions .button--emptyCart:hover {
	background-color:#ececec;
}
.c-product__actions .button--emptyCart i {
	margin-right:0;
}
.not-logged .c-product__actions .button--addBasket {
	opacity:0.4;
	user-select:none;
}
.c-product__packs {
	width:80px;
	font-size:13px;
	font-family:var(--family-primary);
}
.c-product__price {
	font-weight:500;
	font-family:var(--family-primary);
}
.c-product__amount {
	margin-right:calc(var(--spacing) * 4);
}

@media (max-width:890px) {
	.c-product__amount {
		margin-right:var(--spacing);
	}
}
.c-product__amount .control {
	position:relative;
	max-width:100px;
	margin:0 15px 0 0;
}
.c-product__amount i {
	display:inline-block;
	position:absolute;
	top:50%;
	z-index:1;
	color:#6a6a6a;
	font-size:16px;
	cursor:pointer;
}
.c-product__amount i.js-more {
	right:12px;
	transform:rotate(270deg) translatex(50%);
}
.c-product__amount i.js-less {
	left:12px;
	transform:rotate(90deg) translatex(-50%);
}
.c-product__amount input {
	padding:12px 30px;
	border-radius:3px;
	text-align:center;
}
.c-product__total {
	min-width:120px;
	color:var(--blue);
	font-weight:700;
	text-align:right;
}

@media (max-width:890px) {
	.c-product__total {
		text-align:left;
	}
}
.c-product__total-before[class] {
	margin:0;
	color:#202022;
	font-size:12px;
	text-decoration:line-through;
}
.c-product__total-coupon {
	font-size:10px;
}
.c-product__content {
	width:100%;
	margin-top:calc(var(--spacing) / 2);
}
.c-product--has-image .c-product__content {
	margin-left:0;
}
.c-product__content ul {
	display:flex;
	flex-wrap:wrap;
	font-size:1rem;
}
.c-product__content li {
	width:50%;
	padding-right:12px;
	padding-bottom:12px;
}

@media (max-width:890px) {
	.c-product__content li {
		width:100%;
	}
}
.c-product__disclaimer {
	margin-top:calc(var(--spacing) / 2);
	font-style:italic;
	font-size:14px;
}
