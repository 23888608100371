/* =============================================================================
 * Icon
 * postcss-bem-linter: define icon
============================================================================= */
.c-icon {
	display:inline-block;
	width:24px;
	height:24px;
	vertical-align:middle;
	fill:currentColor;
}
.c-icon svg {
	width:100%;
	height:100%;
	margin:auto;
}
.c-icon--xs {
	width:16px;
	height:16px;
}
.c-icon--s {
	width:20px;
	height:20px;
}

/*
.c-icon--m {
	// Default; see first rule in this file
}
*/
.c-icon--l {
	width:32px;
	height:32px;
}
.c-icon--xl {
	width:40px;
	height:40px;
}
.c-icon--ml {
	margin-left:5px;
}
.c-icon--mr {
	margin-right:5px;
}
