/* =============================================================================
 * Text align
============================================================================= */

/* postcss-bem-linter: define text-align-center */
.t-text-align-center {
	text-align:center !important;
}

/* postcss-bem-linter: define text-align-left */
.t-text-align-left {
	text-align:left !important;
}

/* postcss-bem-linter: define text-align-right */
.t-text-align-right {
	text-align:right !important;
}
