/* =============================================================================
 * Browse
 *
 * 1. Row padding and icon width must match for layout to line up correctly.
 * 2. Make clickable area overlap borders aswell.
 *
 * postcss-bem-linter: define browse
============================================================================= */
.c-browse {
	display:inline-block;
	position:relative;
	min-width:220px;
	max-width:350px;
	width:max-content;
	padding:var(--space-xs);
	border:solid 2px var(--blue);
	border-radius:8px;
	transition:background-color 0.15s ease-in-out, color 0.15s ease-in-out;
}
.c-browse__row {
	display:flex;
	align-items:center;
}
.c-browse__row:nth-child(2) {
	padding-left:var(--space-m); /* 1 */
}
.c-browse__icon-container {
	display:flex;
	justify-content:center;
	align-items:center;
	width:var(--space-m); /* 1 */
	color:var(--blue);
	transform:translateY(-2px);
}
.c-browse__icon {
	transition:transform 0.3s ease-in-out;
}
.c-browse__link {
	color:var(--blue);
	font-size:20px;
	font-family:var(--font-bold);
}
.c-browse__link::before {
	position:absolute;
	top:-2px; /* 2 */
	right:-2px; /* 2 */
	bottom:-2px; /* 2 */
	left:-2px; /* 2 */
	z-index:1;
	content:"";
}


/* Next
----------------------------------------------------------------------------- */
.c-browse--next .c-browse__row:nth-child(2) {
	padding-right:var(--space-m); /* 1 */
	padding-left:0;
}
.c-browse--next .c-browse__link {
	margin-left:auto;
}


/* Hover & focus
----------------------------------------------------------------------------- */
.c-browse:hover,
.c-browse:focus-within {
	background-color:var(--blue);
	color:#fff;
}
.c-browse:hover .c-browse__icon,
.c-browse:focus-within .c-browse__icon {
	color:#fff;
	transform:translateX(-4px);
}
.c-browse--next:hover .c-browse__icon,
.c-browse--next:focus-within .c-browse__icon {
	transform:translateX(4px);
}
.c-browse__link:focus {
	text-decoration:underline;
}
.c-browse:focus-within .c-browse__link {
	color:#fff;
	text-decoration:none;
}
