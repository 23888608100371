/* =============================================================================
 * Expand-area
 *
 * 1. !important needed to override ui styling
 * 2. translate + scale needed to override c-button styling
 *
 * postcss-bem-linter: define expand-area
============================================================================= */
.c-expand-area {
	position:relative;
	border:solid 1px var(--light-gray-1);
	border-radius:3px;
}
.c-expand-area__area {
	overflow:auto;
}
.c-expand-area__area--collapsed {
	max-height:400px;
}
.c-expand-area__expand {
	position:absolute;
	top:100%;
	left:50%;
	padding:var(--space-xs) var(--space-s) calc(var(--space-xs) - 3px);
	border:solid 1px var(--light-gray-1) !important;
	border-radius:20px;
	background-color:var(--white);
	transform:translate(-50%, -50%);
}
.c-expand-area .c-expand-area__expand:active {
	transform:translate(-50%, -50%) scale(0.98); /* 2 */
}
