/* =============================================================================
 * Click-area
 *
 * postcss-bem-linter: define click-area
============================================================================= */
.o-click-area {
	position:relative;
	z-index:1;
}
.o-click-area::after {
	position:absolute;
	top:50%;
	left:50%;
	z-index:-1;
	min-width:44px;
	min-height:44px;
	width:100%;
	height:100%;
	content:"";
	transform:translate(-50%, -50%);
}
