/* =============================================================================
 * Visually hidden
 *
 * Hidden visually, but screen readers will read it.
 * postcss-bem-linter: define visually-hidden
============================================================================= */
.t-visually-hidden {
	position:absolute !important;
	width:1px !important;
	height:1px !important;
	overflow:hidden !important;
	clip:rect(1px, 1px, 1px, 1px) !important;
	padding:0 !important;
	border:0 !important;
	white-space:nowrap !important;
}
.t-visually-hidden--focusable:active,
.t-visually-hidden--focusable:focus {
	position:static !important;
	width:auto !important;
	height:auto !important;
	overflow:visible !important;
	clip:auto !important;
	margin:0 !important;
	white-space:inherit !important;
}
