/* =============================================================================
 * Content-list
 *
 * postcss-bem-linter: define content-list
============================================================================= */

/* Reset list styling but maintain list semantics
----------------------------------------------------------------------------- */
.c-content-list {
	margin:0;
	padding-left:0;
	list-style:none;
}
.c-content-list__item::before {
	position:absolute;
	content:"\200B";
}

/* List item
----------------------------------------------------------------------------- */
.c-content-list__item {
	display:flex;
	flex-wrap:wrap;
	align-items:flex-start;
}
.c-content-list__item:not(:first-child) {
	padding-top:var(--space);
	border-top:solid 1px var(--light-blue);
}
.c-content-list__item:not(:last-child) {
	padding-bottom:var(--space);
}
.c-content-list__media-container {
	width:100%;
	margin-bottom:var(--space);
}

/* Media
----------------------------------------------------------------------------- */
.c-content-list__media {
	position:relative;
	flex:0 0 auto;
	height:0;
	padding-bottom:66.667%; /* 3:2 */
}
.c-content-list__media img,
.c-content-list__media iframe {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	border:none;
}

/* Mash up with SectionContentBlock
----------------------------------------------------------------------------- */
.c-content-list__media .is-img-and-vid--wrapper {
	position:absolute;
	width:100%;
	height:100%;
}
.c-content-list__media .is-img.is-vid {
	position:relative;
	width:100%;
	height:100%;
	cursor:pointer;
}
.c-content-list__media .is-img.is-vid iframe {
	display:none;
}
.c-content-list__media .vid-icon {
	position:absolute;
	top:50%;
	right:0;
	left:0;
	z-index:10;
	width:90px;
	height:90px;
	margin:auto;
	border-radius:50%;
	background:#fff;
	line-height:90px;
	text-align:center;
	transform:translatey(-50%);
}
.c-content-list__media .is-img.is-vid .vid-icon::before {
	position:relative;
	color:#202022;
	font-size:80px;
	font-family:"icons", sans-serif;
	content:"\e90f";
}

/* Media queries
----------------------------------------------------------------------------- */

@media screen and (min-width:600px) {
	.c-content-list__item {
		flex-wrap:nowrap;
	}
	.c-content-list__media-container {
		flex:0 0 auto;
		min-width:280px;
		width:30%;
		margin-bottom:0;
	}
	.c-content-list__text-wrapper {
		max-width:85ch;
		padding:var(--space-s) 0 0 var(--space-s);
	}
}
