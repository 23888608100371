/* =============================================================================
 * Quick-search
 *
 * postcss-bem-linter: define quick-search
============================================================================= */
.c-quick-search {
	position:relative;
	background-color:var(--light-gray-2);
}
.c-quick-search__destination:not(:empty) {
	display:flex;
	justify-content:center;
	align-items:center;
	min-height:200px;
	padding-top:var(--space-s);
	padding-bottom:var(--space-s);
}
.c-quick-search__new-content {
	position:relative;
	width:100%;
	padding:var(--space-m) var(--space-s);
	background-color:#fff;
}
.c-quick-search__close {
	position:absolute;
	top:var(--space-s);
	right:var(--space-s);
	padding:4px;
	border:solid 1px var(--light-gray-1);
	border-radius:50px;
	background-color:transparent;
	cursor:pointer;
}
.c-quick-search__close:active {
	color:var(--black);
	transform:scale(0.95);
}
.c-quick-search__close:hover {
	background-color:var(--light-gray-1);
	color:var(--white);
}
.c-quick-search__close:focus {
	outline:var(--focus-ring);
	outline-offset:-5px;
}
.c-quick-search__hits {
	max-width:calc(100% - 80px);
	margin-right:auto;
	margin-left:auto;
	font-family:var(--font-bold);
}
.c-quick-search__view-all {
	margin-top:var(--space-l);
	text-align:center;
}

@media screen and (max-width:768px) {
	.c-quick-search__new-content {
		padding-right:0;
		padding-left:0;
	}
}