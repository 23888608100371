/* =============================================================================
 * Responsive table
 *
 * postcss-bem-linter: define responsive-table
============================================================================= */


/* Change display properties
----------------------------------------------------------------------------- */
.c-responsive-table--transformed,
.c-responsive-table--transformed caption,
.c-responsive-table--transformed tbody,
.c-responsive-table--transformed tfoot,
.c-responsive-table--transformed tr,
.c-responsive-table--transformed th,
.c-responsive-table--transformed td {
	display:block;
}
.c-responsive-table--transformed thead {
	display:none;
}


/* Caption
----------------------------------------------------------------------------- */
.c-responsive-table--transformed caption {
	padding:var(--space-s);
	background-color:var(--light-gray-4);
}


/* Margin, padding and borders
----------------------------------------------------------------------------- */
.c-responsive-table--transformed tr > * {
	padding:var(--space-s);
}
.c-responsive-table--transformed tr > *:not(:last-child) {
	padding-bottom:0;
	border-bottom:none;
}
.c-responsive-table--transformed th > *:last-child,
.c-responsive-table--transformed td > *:last-child {
	margin-bottom:0;
}


/* Zebra striping
----------------------------------------------------------------------------- */
.c-responsive-table--transformed tr:nth-child(odd) {
	background-color:var(--light-gray-4);
}


/* Headers
----------------------------------------------------------------------------- */
.c-responsive-table--transformed th {
	font-weight:normal;
}
.c-responsive-table__fake-header {
	display:block;
	font-weight:bold;
}
