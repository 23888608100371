/* =============================================================================
 * Animations
============================================================================= */

/* Slide in
----------------------------------------------------------------------------- */
.b-animation-slide-in {
	animation:slideIn 0.3s ease-in-out forwards;
}

@keyframes slideIn {
	0% {
		opacity:0;
		transform:translateY(3rem);
	}
	75% {
		transform:translateY(-0.5rem);
	}
	100% {
		opacity:1;
		transform:translateY(0);
	}
}

/* Slide out
----------------------------------------------------------------------------- */
.b-animation-slide-out {
	animation:slideOut 0.35s ease-in-out forwards;
}

@keyframes slideOut {
	0% {
		opacity:1;
		transform:translateY(0);
	}
	30% {
		transform:translateY(-0.5rem);
	}
	100% {
		opacity:0;
		transform:translateY(4rem);
	}
}

/* Fade in
----------------------------------------------------------------------------- */
.b-animation-fade-in {
	animation:fadeIn 0.2s ease-in-out forwards;
}

@keyframes fadeIn {
	0% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
}

/* Reduce motion
----------------------------------------------------------------------------- */
@media (prefers-reduced-motion:reduce) {
	.b-animation-slide-in,
	.b-animation-slide-out {
		animation:none;
	}
}
