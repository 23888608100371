/* =============================================================================
 * Button
 *
 * 1. Needed to reset any styles set on a:visited in case the `c-button` class
 *    is used on `a` elements.
 * 2. Makes sure button, input and a elements are aligned vertically.
 * 3. Shrink the button to simulate a click.
 * 4. !important needed to override ui styling
 * 5. Needed for color contrast 3:1 instead of 4.5:1 (bold and 18.5px)
 *
 * postcss-bem-linter: define button
============================================================================= */
.c-button,
.c-button:visited /* 1 */ {
	display:inline-flex;
	justify-content:center;
	align-items:center;
	min-width:100px;
	width:auto;
	overflow:visible;
	padding:calc(var(--space-s) - 2px) calc(var(--space-s) * 1.5) var(--space-s);
	border:none !important; /* 4 */
	border-radius:3px;
	background-color:var(--light-gray-2);
	color:var(--black);
	font-size:var(--font-size-body);
	line-height:1.3;
	font-family:var(--font-bold);
	vertical-align:middle; /* 2 */
	text-align:center;
	text-decoration:none;
	white-space:normal;
	word-break:break-word;
	cursor:pointer;
	transition:none !important; /* 4 */
}
.c-button * {
	font-family:var(--font-bold);
}
.c-button:hover,
.c-button:focus {
	/* TODO: Contrast not good enough */
	background-color:var(--light-gray-4);
	color:var(--black);
	opacity:1 !important;
}
.c-button:focus {
	outline:var(--focus-ring);
	outline-offset:2px;
}
.c-button:active:not([disabled]) {
	transform:scale(0.98); /* 3 */
}
.c-button[disabled],
.c-button[disabled]:hover,
.c-button[disabled]:focus {
	background-color:var(--light-gray-1);
	color:var(--light-gray-2);
	cursor:not-allowed;
}

/* Icon only
----------------------------------------------------------------------------- */
.c-button--icon-only {
	min-width:0;
	padding:var(--space-s);
	border-radius:50px;
}

/* Buttons with icon
----------------------------------------------------------------------------- */
.c-button:not(.c-button--icon-only) > :first-child {
	margin-right:var(--space-xs);
}

/* Styles
----------------------------------------------------------------------------- */
.c-button--primary,
.c-button--primary:visited {
	background-color:var(--blue);
	color:#fff;
}
.c-button--primary:hover,
.c-button--primary:focus {
	background-color:#2265b0;
	color:#fff;
}
.c-button--buy,
.c-button--buy:visited {
	/* TODO: Contrast not good enough, changed #88b700 to #68A500 */
	background-color:var(--green);
	color:#fff;
	font-weight:700; /* 5 */
}
.c-button--buy:hover,
.c-button--buy:focus {
	background-color:#a3c932;
	color:#fff;
}

/* Go to-link
----------------------------------------------------------------------------- */
.c-button--go-to,
.c-button--go-to:visited {
	display:inline-block;
	min-width:0;
	padding:var(--space-xs) 0;
	background-color:transparent;
	color:var(--blue);
}
.c-button--go-to:hover,
.c-button--go-to:focus {
	background-color:transparent;
	color:var(--blue);
}
.c-button--go-to:hover > :first-child,
.c-button--go-to:focus > :first-child {
	text-decoration:underline;
}
.c-button--go-to[disabled],
.c-button--go-to[disabled]:hover,
.c-button--go-to[disabled]:focus {
	background-color:transparent;
	color:var(--light-gray-1);
}

@media (prefers-reduced-motion:no-preference) {
	.c-button--go-to:not([disabled]) > :first-child {
		transition:margin-right 0.2s ease-in-out;
	}
	.c-button--go-to:not([disabled]):hover > :first-child,
	.c-button--go-to:not([disabled]):focus > :first-child {
		margin-right:var(--space-s);
	}
}
