/* =============================================================================
 * Link-block
 *
 * 1. Disable 'transform: scale' that is inherited from the button component
 *
 * postcss-bem-linter: define link-block
============================================================================= */
.c-link-block {
	position:relative;
	border:solid 1px var(--light-gray-2);
	border-bottom-width:3px;
	background-color:var(--white);
	text-align:center;
	transition:border-color 0.2s ease;
}
.c-link-block:hover,
.c-link-block:focus-within {
	border-bottom-color:var(--blue);
}
.c-link-block:focus-within {
	outline:var(--focus-ring);
}
.c-link-block__icon {
	margin-top:var(--space-m);
	margin-bottom:var(--space-m);
}
.c-link-block__image {
	width:100%;
}
.c-link-block__text-content {
	display:flex;
	flex-direction:column;
	height:100%;
	padding:var(--space-s) var(--space-s) 0;
}
.c-link-block__heading {
	max-width:40ch;
	margin-right:auto;
	margin-left:auto;
	padding-top:var(--space-xs);
	font-size:20px;
}
.c-link-block .c-link-block__description {
	max-width:50ch;
	margin:0 auto var(--space-s);
}
.c-link-block__link {
	margin-top:auto;
	margin-bottom:var(--space-s);
	padding:0;
}
.c-link-block__link::before {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	content:"";
}
.c-link-block .c-link-block__link:active {
	transform:none; /* 1 */
}
.c-link-block__link:focus {
	outline:none;
}
.c-link-block__link:hover > :first-child,
.c-link-block__link:focus > :first-child {
	text-decoration:none;
}
