/* =============================================================================
 * Custom checkbox
 *
 * 1. We use flex to ensure that if the label text wraps, it does not wrap below
 *    the checkbox.
 *
 * postcss-bem-linter: define custom-checkbox
============================================================================= */
.c-custom-checkbox {
	display:flex; /* 1 */
	align-items:center;
}
.c-custom-checkbox + .c-custom-checkbox {
	margin-top:var(--space-s);
}
.c-custom-checkbox__input-container {
	display:inline-block;
	position:relative;
	flex:none; /* 1 */
	width:24px;
	height:24px;
	vertical-align:middle;
}
.c-custom-checkbox__input {
	position:absolute;
	top:0;
	left:0;
	z-index:1;
	width:100%;
	height:100%;
	opacity:0;
}
.c-custom-checkbox__input-graphic {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	border:1px solid var(--light-gray-1);
	border-radius:4px;
	color:var(--gray);
	transition:border-color 0.05s linear;
}
.c-custom-checkbox__input-graphic .c-custom-checkbox__icon {
	position:absolute;
	top:50%;
	left:50%;
	opacity:0;
	transition:translate 0.1s linear, opacity 0.1s linear;
	transform:translate(-50%, -50%) scale(0.6);
}
.c-custom-checkbox label {
	margin-bottom:0;
	margin-left:0.5em;
	word-wrap:break-word;
	overflow-wrap:word-break;
}


/* Checked
----------------------------------------------------------------------------- */
.c-custom-checkbox__input:checked + .c-custom-checkbox__input-graphic .c-custom-checkbox__icon {
	opacity:1;
	transform:translate(-50%, -50%) scale(1);
}


/* Hover
----------------------------------------------------------------------------- */
.c-custom-checkbox__input:not([disabled]):hover + .c-custom-checkbox__input-graphic {
	border-color:var(--blue);
	color:var(--black);
}


/* Focus
----------------------------------------------------------------------------- */
.c-custom-checkbox__input:not([disabled]):focus + .c-custom-checkbox__input-graphic {
	border-color:var(--black);
	color:var(--black);
}


/* Disabled
----------------------------------------------------------------------------- */
.c-custom-checkbox--disabled .c-custom-checkbox__input-graphic,
.c-custom-checkbox--disabled .c-custom-checkbox__label {
	opacity:0.5;
}
.c-custom-checkbox__input[disabled] {
	cursor:not-allowed;
}
