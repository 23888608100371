/* =============================================================================
 * Video
 *
 * postcss-bem-linter: define video
============================================================================= */
.c-video {
	position:relative;
}
.c-video[data-ratio] {
	height:auto;
}
.c-video--background {
	width:100%;
	height:100%;
	overflow:hidden;
}
.c-video--full-page {
	height:100vh;
}
.c-video--background .c-video__video {
	position:absolute;
	top:50%;
	left:50%;
	min-width:100%;
	max-width:200%;
	min-height:100%;
	max-height:300%;
	width:auto;
	height:auto;
	transform:translate(-50%, -50%);
}

@supports (object-fit: cover) {
	.c-video--background .c-video__video {
		top:0;
		right:0;
		bottom:0;
		left:0;
		width:100%;
		height:100%;
		object-fit:cover;
		transform:none;
		object-position:center;
	}
}
