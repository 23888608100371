/* =============================================================================
 * Aspect-ratio
 *
 * NOTE: This is not defined as a BEM component since postcss-bem-linter can’t
 *       handle `:` inside attribute selectors properly and incorrectly
 *       reports the selectors we use to for `data-ratio` as errors.
============================================================================= */
.o-aspect-ratio {
	overflow:hidden;
}
.o-aspect-ratio[data-ratio="2:1"] .o-aspect-ratio__inner {
	padding-bottom:50%;
}
.o-aspect-ratio__inner,
.o-aspect-ratio[data-ratio="16:9"] .o-aspect-ratio__inner {
	height:0;
	padding-bottom:56.25%;
}
.o-aspect-ratio[data-ratio="4:3"] .o-aspect-ratio__inner {
	padding-bottom:75%;
}
.o-aspect-ratio[data-ratio="1:1"] .o-aspect-ratio__inner {
	padding-bottom:100%;
}
