/* =============================================================================
 * Background-color
============================================================================= */
.t-background-color-blue {
	background-color:var(--blue);
	color:#fff;
}
.t-background-color-blue-light {
	background-color:var(--lightest-blue);
}
.t-background-color-gray {
	background-color:var(--light-gray-4);
}
